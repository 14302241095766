import { FC, useMemo, useRef, useState } from "react"
import { InputGroup, FormControl, Alert } from "react-bootstrap"
import { useRedeemedInfo } from "../../store"
import "./SecurityCard.scss"
import moment from "moment"
import ReCAPTCHA from "react-google-recaptcha";

interface ISecurityCardProps {
  securityCode: string
  voucherDetails?: VoucherDetails
  recaptcha: string | null
  setSecurityCode: (securityKey: string) => void
  setReCaptcha: (recaptha: string | null) => void
  onSubmit: (id?: number) => void
}

//Display Gift Voucher
const SecurityCard: FC<ISecurityCardProps> = ({
  securityCode,
  setSecurityCode,
  recaptcha,
  setReCaptcha,
  onSubmit,
  voucherDetails,
}) => {
  const { error, message, data } = useRedeemedInfo()

  const disabledInput = useMemo(
    () =>
      (!!data || (voucherDetails?.attemptWithVendor || 0) > 2) &&
      voucherDetails?.state !== "issued",
    [data, voucherDetails]
  )

  const onCaptchaChange = (value: string | null) =>{
    console.log(value)
    setReCaptcha(value)
  }

  return (
    <>
      {!data && voucherDetails && (
        <Alert data-cy="organization-name-and-expiry" variant="info">
          <div className="text-blue-500">
            Your gift card is ready.
            <br />
            This giftcard will expire on{" "}
            <span className="font-bold">
              {moment(voucherDetails.expiryDate).format("DD MMM, YYYY")}
            </span>{" "}
            if not claimed.
          </div>
        </Alert>
      )}
      <div className="containerSecu mt-1">
        {(error || message) && (
          <Alert
            data-cy="security-key-warning"
            variant={error ? "danger" : "success"}
          >
            <div className={error ? "text-red-500" : "text-green-500"}>
              {error && error === "Unauthorized"
                ? "Invalid security code"
                : error
                ? error
                : message}
            </div>
          </Alert>
        )}

        <InputGroup size="lg" className="mx-auto">
          <InputGroup.Text>Security Code</InputGroup.Text>
          <FormControl
            data-cy="security-key-input"
            value={securityCode}
            onChange={(e: any) => setSecurityCode(e.target.value)}
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            placeholder="Enter Security Code"
            disabled={disabledInput}
          />
        </InputGroup>
        <div className="container">
          <div className="row p-3">
            <div className="col-md-6 offset-md-2 text-center p-3">
              <ReCAPTCHA onChange={onCaptchaChange} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''} />
            </div>
            <div className="col-md-6 offset-md-3 text-center">
              <button
                data-cy="submit-button-main"
                onClick={() => onSubmit()}
                className="submitButton btn-default"
                disabled={disabledInput || !securityCode || !recaptcha}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecurityCard
