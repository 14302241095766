import create from "zustand"

interface IRedeemedInfoStore {
  data?: RedeemedInfo
  error?: string
  message?: string

  setRedeemedInfo: (data: RedeemedInfo) => void
  setError: (error: string) => void
  setMessage: (message: string) => void
}

export const useRedeemedInfo = create<IRedeemedInfoStore>((set) => ({
  data: undefined,
  error: undefined,
  message: undefined,

  setRedeemedInfo: (data) => set({ data }),
  setError: (error) => set({ error }),
  setMessage: (message) => set({ message }),
}))

interface IAccountDetailsStore {
  website?: string

  setWebsite: (website: string) => void
}

export const useAccountDetails = create<IAccountDetailsStore>((set) => ({
  website: undefined,
  setWebsite: (website) => set({ website }),
}))
