import axios from '../axios'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAccountDetails } from '../../store'

export const useProductDetails = () => {
  const [error, setError] = useState('')
  const [data, setData] = useState<{ data: ProductDetails | null; message: string}>()
  const [loading, setLoading] = useState(true)
  const [params] = useSearchParams()

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = () =>
    axios
      .get<{ data: ProductDetails | null; message: string }>(
        '/redeem-product-details',
        {
          params: { securityKey: params.get('securityKey') },
        },
      )
      .then((r) => {
        setData(r.data)
      })
      .catch((e) =>
        setError(e.response?.data?.error || 'Error fetching product data!'),
      )
      .finally(() => setLoading(false))

  return {
    data,
    error,
    loading,
    refetch: fetch,
  }
}

export enum State {
  Issued = 'issued',
  Waiting = 'waiting',
  Cancelled = 'cancelled',
}

export const useVoucherDetails = () => {
  const [params] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [data, setData] = useState<VoucherDetails>()

  const setWebsite = useAccountDetails(state => state.setWebsite)

  const fetch = () => {
    if (!params.get('securityKey')) {
      setLoading(false)
      return
    }

    setLoading(true)
    return axios
      .get<VoucherDetails>('/verify-voucher-info', {
        params: { securityKey: params.get('securityKey') },
      })
      .then((r) => {
        setData(r.data)
        setWebsite(r.data.website)
      })
      .catch((e) =>
        setError(e.response?.data?.error || 'Error fetching voucher data!'),
      )
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { data, error, loading, refetch: fetch }
}

export const useAlternateProducts = (voucherDetails?: VoucherDetails, message?: string) => {
  const [params] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<AlternateProducts>()

  useEffect(() => {
    fetch()
  }, [voucherDetails, message]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = () => {
    if (!params.get('securityKey')) return
    if (!voucherDetails) return
    if (voucherDetails.attemptWithVendor < 3 && !message?.includes('not available')) return

    setLoading(true)
    return axios
      .get<AlternateProducts>(`/alternateBrands`, {
        params: {
          securityKey: params.get('securityKey'),
        },
      })
      .then((r) => setData(r.data))
      .catch((e) =>
        setError(
          e.response?.data?.error || 'Error fetching alternate products!',
        ),
      )
      .finally(() => setLoading(false))
  }

  return {
    data,
    loading,
    error,
    refetch: fetch,
  }
}
