import "./styles/App.scss"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Redeem from "./pages/Redeem"
import Home from "./pages/Home"
import { QueryParamProvider } from "use-query-params"
import { Toaster } from "react-hot-toast"
import "antd/dist/antd.css"
import Header from "./Components/Header"
import Footer from "./Components/Footer"

const App = () => {
  return (
    <QueryParamProvider>
      <div className="bg-primary-gray min-h-screen">
        <Header />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/redeem" element={<Redeem />} />
          </Routes>
        </Router>
        <Toaster />
        <Footer />
      </div>
    </QueryParamProvider>
  )
}

export default App
