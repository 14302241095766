import { Spin } from 'antd'
import { FC } from 'react'

const ScreenLoader: FC<{
  children: any
  loading: boolean
}> = ({ children, loading }) => {
  if (loading) return <Spin tip="Loading...">{children}</Spin>

  return children
}

export default ScreenLoader
