import Footer from "../Components/Footer"
import Header from "../Components/Header"

const Home = () => {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "0 50px",
        }}
      >
        <h1>Rewards Page</h1>
      </div>
      <Footer />
    </>
  )
}

export default Home
